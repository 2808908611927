import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import AddIcon from 'assets/icons/Add/AddIcon';
import { CertificationsIcon } from 'assets/icons/Certificate/CertificationsIcon';
import { ClinicalQualificationsIcon } from 'assets/icons/CredentialsWallet/ClinicalQualifications';
import { EducationOnlineExamsIcon } from 'assets/icons/CredentialsWallet/EducationOnlineExams';
import { EmployeeHealthIcon } from 'assets/icons/CredentialsWallet/EmployeeHealth';
import { HumanResourcesIcon } from 'assets/icons/CredentialsWallet/HumanResources';
import { LicensesIcon } from 'assets/icons/CredentialsWallet/Licenses';
import { OpenRequirementsIcon } from 'assets/icons/CredentialsWallet/OpenRequirements';
import { SubmissionsIcon } from 'assets/icons/CredentialsWallet/Submissions';
import { ReactComponent as PlaneIcon } from 'assets/icons/Send.svg';
import { TaskIcon } from 'assets/icons/TaskIcon';
import { IDropdown } from 'classes/Dropdown/Dropdown';
import { AssignmentLink } from 'components/AssignmentDetail/AssignmentLink';
import { UploadedCredentialDocuments } from 'components/Credentials/AddCredential/UploadedCredentialDocuments';
import { CardDetails } from 'components/common/Cards/CardDetails';
import { AppCircularProgress } from 'components/common/CircularProgress/CircularProgress';
import { DrawerPanel } from 'components/common/Drawer/Drawer';
import { IFrameDrawer } from 'components/common/Drawer/IFrameDrawer';
import { TextButton } from 'components/common/Link/TextButton';
import { CardListPage } from 'components/common/PageLayout/CardListPage';
import { TaskActionsManager } from 'components/common/TaskCenter/TaskActionsManager';
import { NO_DATA_MESSAGE, TOAST_MESSAGE } from 'constants/helperText';
import {
  HEALTHSTREAM_URL,
  NURSE_LICENSURE_GUIDE_URL,
} from 'constants/passportUrls';
import { useMobileMediaQuery } from 'containers/MobileDesktopContainer/useMediaQuery';
import { AlertBarType } from 'enums/alertBarTypes';
import {
  CredentialOpenRequirementsStatus,
  CredentialSubmissionStatus,
  OpenRequirementCredentialType,
  SubmissionType,
  userCredentials,
} from 'enums/credential';
import { DataLayerPageName } from 'enums/dataLayer';
import { DrawerContentNames } from 'enums/drawerContentNames';
import { FeatureFlagType } from 'enums/featureFlags';
import { DrawerHeaderType } from 'enums/headerTypes';
import { MenuIcons } from 'enums/menuIcons';
import { UserStatusTypeId } from 'enums/userStatusTypeId';
import {
  addDownload,
  credentialSubmissionConfirmationPopup,
  filterRedirectedOpenRequirement,
  formatAssignmentCredentialSubmissions,
  formatForOpenRequirements,
  getDrawerChildren,
  getDrawerChildrenSubmission,
  itemsOtherProps,
  itemsOtherPropsSubmission,
  orderCredentialSubmissions,
  sortByDueDate,
  sortOpenRequirements,
} from 'helpers/credentialCenterHelper';
import { isSafariOrFirefox, openInNewTab } from 'helpers/openInNewTab';
import {
  ICredentialDetails,
  ICredentialSubmission,
  IOpenRequirementsDetails,
} from 'interfaces/Credential/ICredentialDetails';
import { ICountryAndProvince } from 'interfaces/Lookup/ICountryAndProvince';
import {
  ICard,
  ICardDetails,
  ICardList,
} from 'interfaces/PageLayout/ICardListPage';
import { IDropdownOption } from 'interfaces/Props/IDropdownOption';
import { trackEvent } from 'services/logging/appInsights';
import { RootState, useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { credentialActions } from 'store/slices/credentials/credentialSlice';
import { dataLayerActions } from 'store/slices/dataLayer/dataLayerSlice';
import { fileUploadsActions } from 'store/slices/fileUpload/fileUploadSlice';
import { setActivePage } from 'store/slices/navigation/navigationSlice';
import { resetSkillsList } from 'store/slices/skillsChecklists/skillsChecklistsSlice';
import { desktopWidth } from 'styles/media';
import { theme } from 'styles/theme';
import { convertBase64ToUrl } from 'utils/helper';
import useQueryParams from 'utils/hooks/useQueryParams';
import useTrackPageView from 'utils/hooks/useTrackPageView';
import { OpenTasksSplash } from './OpenTasksSplash';
import { TaskCenterSplash } from './TaskCenterSplash';
import {
  filterCredentials,
  getRnOrLpnContentName,
  returnBadgeStatus,
  visualIndicatorColor,
} from './helper';
import { AppRouteNames } from 'routes/appRouteNames';
import { actionItemActions } from 'store/slices/actionItem/actionItemSlice';
import { fetchDiscipline } from 'store/slices/lookups/lookupsSlice';
import { UserDisciplineTypeId } from 'enums/userDisciplineTypeId';
import { usePromiseTracker } from 'react-promise-tracker';
import moment from 'moment';
import { ssnDobTargetTypes } from 'pages/Assignments/assignmentHelpers';
import { actionItemTypeId } from 'enums/actionType';
import { FeatureToggles } from 'enums/featureToggles';
import { useFeatureToggle } from 'components/FeatureToggle/useFeatureToggle';

const DUE_DATE_VALUE = 'duedate';
const CATEGORIES_VALUE = 'categories';
const ALL_VALUE = 'all';

export const TaskCenterPage = () => {
  const matchesMinWidth1024 = useMediaQuery(desktopWidth);
  const isMobileDevice = useMobileMediaQuery();
  const dispatch = useAppDispatch();
  const query = useQueryParams();
  const history = useHistory();

  const featureFlags = useAppSelector(state => state.userProfile.featureFlags);
  const credentials = useAppSelector((state: RootState) => state.credential);
  const submittedAssignmentCredentials = useAppSelector(
    state => state.credential.assignmentCredentialSubmissions,
  );
  const walletSubmittedCredentials = useAppSelector(
    state => state.credential.submissions,
  );
  const credentialChecklistData = useAppSelector(
    state => state.credential?.credentialsChecklist,
  );
  const signedArbitrationAgreement = useAppSelector(
    state => state.credential?.signedArbitrationAgreement,
  );
  const userStatusTypeId = useAppSelector(
    state => state.userProfile.userStatusTypeId,
  );
  const isLeadUser = userStatusTypeId === UserStatusTypeId.LEAD;
  const loadedForTagManager = useAppSelector(
    state => state.assignmentSummary.loadedForTagManager,
  );
  const isOpen = useAppSelector(state => state.credential.isOpenModal);
  const skillListUrl = useAppSelector(
    (state: RootState) => state.auth.versionCheck?.skillsChecklistViewUrl,
  );
  const stateList: ICountryAndProvince[] = useAppSelector(
    (state: RootState) => state.lookup.stateAndProvince || [],
  );
  const drawerOpen = useAppSelector(
    state => state.credential.taskCredentialActionDrawer,
  );
  const filteredOpenCredentialsOpenItems = useAppSelector(
    state =>
      state.credential.openRequirements.filter(
        t =>
          (t?.skillsChecklist?.latestCompletedDate == null ||
            moment(t?.skillsChecklist?.latestCompletedDate).isBefore(
              moment().subtract(3, 'years'),
            )) &&
          t?.assignmentCredential?.statusId !==
            CredentialOpenRequirementsStatus.Pending &&
          t?.assignmentCredential?.statusId !==
            CredentialOpenRequirementsStatus.Accepted &&
          t?.userCredential?.statusId !== CredentialSubmissionStatus.Pending,
      ).length,
  );
  const userDisciplineId = useAppSelector(
    state => state.userProfile.userDisciplineTypeId,
  );
  const isAssignmentProfileTasksEnabled = useFeatureToggle(
    FeatureToggles.ENABLE_ASSIGNMENT_PROFILE_TASKS,
  );

  const { promiseInProgress: isDisciplineLoading } = usePromiseTracker({
    area: 'get-discipline-data',
    delay: 0,
  });

  const [selectedId, setSelectedId] = useState<number>();
  const [selectedDetailsItem, setSelectedDetailsItem] =
    useState<ICredentialDetails>();
  const [selectedSubmission, setSelectedSubmission] =
    useState<ICredentialSubmission>();
  const [selectedOpenTask, setSelectedOpenTask] = useState<any>(undefined);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [showDetailsDrawer, setShowDetailsDrawer] = useState<boolean>(false);
  const [sortValue, setSortValue] = useState<string | undefined>(
    CATEGORIES_VALUE,
  );
  const [assignmentSort, setAssignmentSort] = useState<
    string | number | undefined
  >(ALL_VALUE);
  const [downloadActive, setDownloadActive] = useState<boolean>(false);
  const [downloadList, setDownloadList] = useState<any>({});
  const [expandAccordion, setExpandAccordion] = useState<string | undefined>(
    undefined,
  );
  const [defaultExpandedAccordion, setDefaultExpandedAccordion] = useState<
    string | undefined
  >();
  const [selectedPlacementId, setSelectedPlacementId] = useState<number>(0);
  const [showAllAssignments, setShowAllAssignments] = useState<boolean>(false);

  const categoryId = query.get('categoryId');
  const credentialId = query.get('credentialId');
  const placementId = query.get('placementId');
  const openTasks = query.get('openRequirements');
  const credentialTypeId = query.get('credentialType');
  const documentItemId = query.get('myDocumentItem');
  const documentCategory = query.get('myDocumentCategory');

  useTrackPageView('TaskCenter');

  const dispatchDrawerAction = action =>
    dispatch(credentialActions.setTaskCredentialActionDrawer(action));

  const openAddCredentialDrawer = () => {
    if (selectedId !== userCredentials.LICENSES) {
      dispatchDrawerAction(DrawerContentNames.ADD_CREDENTIAL);
      // AppInsights Tracking: Credential Center Add Credential Clicked
      trackEvent('CredentialCenterPage_Add_Clicked');
    } else {
      dispatch(credentialActions.setLicenseTypeAndDiscipline(undefined));
      dispatch(actionItemActions.setSelectedClearanceItem(undefined));
      dispatchDrawerAction(DrawerContentNames.LICENSE_VERIFICATION);
    }
  };
  const openRequirementsData = useCallback(
    (reqs: IOpenRequirementsDetails[]) =>
      reqs.map(req =>
        formatForOpenRequirements(
          req,
          setSelectedOpenTask,
          dispatch,
          skillListUrl,
          false,
          history,
        ),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [credentials.openRequirements, setSelectedOpenTask],
  );

  const formatAssignmentList = useCallback(
    (list: IOpenRequirementsDetails[]) => {
      const dropdownOptions: IDropdownOption[] = [
        {
          key: ALL_VALUE,
          value: 'All',
        },
      ];

      const uniquePairs = new Map<string, IDropdownOption>();

      list.forEach(item => {
        if (
          item.actionItem &&
          item.actionItem.facilityName &&
          item.actionItem.placementId
        ) {
          const option: IDropdownOption = {
            key: item.actionItem.placementId,
            value: item.actionItem.facilityName,
          };
          uniquePairs.set(`${option.key}:${option.value}`, option);
        } else if (
          item.assignmentCredential &&
          item.assignmentCredential.facilityName &&
          item.assignmentCredential.placementId
        ) {
          const option: IDropdownOption = {
            key: item.assignmentCredential.placementId,
            value: item.assignmentCredential.facilityName,
          };
          uniquePairs.set(`${option.key}:${option.value}`, option);
        }
      });

      uniquePairs.forEach(pair => dropdownOptions.push(pair));

      return dropdownOptions;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [credentials.openRequirements],
  );

  const sortByAssignment = useCallback(
    (reqs: IOpenRequirementsDetails[], includeDobSsn?: boolean) =>
      assignmentSort === ALL_VALUE
        ? reqs
        : reqs?.filter(
            r =>
              r.actionItem?.placementId === assignmentSort ||
              r.assignmentCredential?.placementId === assignmentSort ||
              (includeDobSsn &&
                isAssignmentProfileTasksEnabled &&
                ssnDobTargetTypes.includes(
                  r?.actionItem?.actionItemTypeId as actionItemTypeId,
                )),
          ),
    [assignmentSort],
  );

  const onShowMore = (showmore: boolean) => {
    const action = {
      offset: credentials?.submissions.paging.limit,
      limit: credentials?.submissions.paging.total,
    };
    if (showmore) {
      if (userCredentials.Submissions === selectedId)
        dispatch(credentialActions.loadMoreSubmissions(action));
    } else {
      dispatch(credentialActions.getSubmissions());
    }
  };

  const openCOVID19Drawer = () => {
    dispatchDrawerAction(DrawerContentNames.CREDENTIAL_COVID_VACCINE_RECORD);
    // AppInsights Tracking: Credential Category Page Viewed
    trackEvent(
      `TaskCenterPage_Category_Viewed_${userCredentials.VaccinationRecord}`,
      {
        categoryId: userCredentials.VaccinationRecord,
        category: 'COVID-19 Vaccination Record',
      },
    );
  };

  const openAddIdentificationDrawer = () => {
    dispatchDrawerAction(DrawerContentNames.ADD_IDENTIFICATION);
  };

  const toggleDownloadDocs = useCallback(() => {
    setDownloadActive(prevState => !prevState);
    setDownloadList({});
  }, []);

  const onSearchBarFocus = useCallback(() => {
    setSelectedId(undefined);
    trackEvent('TaskCenterPage_Search_Viewed');
  }, []);

  const documentDocs = useCallback(() => {
    if (!_isEmpty(downloadList)) {
      let ids = Object.keys(downloadList)
        .map(item => item)
        .join(',');
      dispatch(credentialActions.getCredentialDocuments(ids));
      // AppInsights Tracking: Credential Category Download button clicked
      trackEvent('TaskCenterPage_Download_Clicked', {
        downloadListLength: (Object.keys(downloadList) || []).length,
      });
    } else {
      dispatch(
        openAlert({
          variant: 'error',
          message: TOAST_MESSAGE.NoDocumentForDownload,
        }),
      );
    }
    toggleDownloadDocs();
  }, [downloadList, dispatch, toggleDownloadDocs]);

  const toggleDownloads = useCallback(
    id => {
      let tempDownload = Object.assign({}, downloadList);
      !!tempDownload[id] ? delete tempDownload[id] : (tempDownload[id] = true);

      setDownloadList(tempDownload);
    },
    [downloadList],
  );

  const createNoDataScreen = useCallback(
    (icon: any | undefined, message: string) => (
      <>
        <Grid
          container
          id="no-data-screen"
          sx={{ height: { xs: 'calc(100vh - 144px)', sm: '172px' } }}
        >
          {icon && (
            <Grid
              item
              sx={{
                pb: 2,
              }}
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="flex-end"
            >
              {icon}
            </Grid>
          )}

          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            textAlign="center"
          >
            <Typography
              variant="subtitle1"
              color="system.coolGray"
              justifyContent={icon ? 'inherit' : 'center'}
              display={icon ? 'inherit' : 'flex'}
              alignItems={icon ? 'inherit' : 'center'}
            >
              <i>{message}</i>
            </Typography>
          </Grid>
        </Grid>
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedId],
  );

  const useOpenRequirementsFlag = useMemo(
    () =>
      featureFlags &&
      featureFlags[FeatureFlagType.Credential_Center]?.isEnabled,
    [featureFlags],
  );

  const useDocusignFlag = useMemo(
    () =>
      featureFlags &&
      featureFlags[FeatureFlagType.Credential_Center_Docusign]?.isEnabled,
    [featureFlags],
  );

  const allOpenRequirements: any[] = useMemo(() => {
    let allCredentials = credentials.credentials?.map(credential => ({
      ...credential,
      ...itemsOtherProps(credential),
    }));

    if (useOpenRequirementsFlag) {
      const openReqs = openRequirementsData(credentials.openRequirements);
      openReqs.forEach(item => {
        allCredentials = allCredentials.filter(cred => cred?.id !== item?.id);
      });
      return openReqs.concat(allCredentials as any);
    } else {
      return allCredentials;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    credentials.openRequirements,
    credentials.credentials,
    downloadActive,
    downloadList,
    useOpenRequirementsFlag,
  ]);

  const openRequirementItems = credentials?.openRequirements.filter(
    (t: IOpenRequirementsDetails) =>
      t?.skillsChecklist?.latestCompletedDate == null ||
      moment(t?.skillsChecklist?.latestCompletedDate).isBefore(
        moment().subtract(3, 'years'),
      ),
  );

  const taskVerbiage = (
    <>
      <Typography
        variant="subtitle1"
        color="system.grayText"
        margin="3px 0 5px 0"
        display="flex"
        alignItems="center"
        gap="5px"
      >
        Quick Action, Quick Results <PlaneIcon width="18px" height="18px" />
      </Typography>
      <Typography variant="body1" color="system.coolGray" marginBottom="24px">
        Most jobs are fully booked within hours, knock out these tasks to land
        your dream job.
      </Typography>
    </>
  );

  const cardDetailsData = useMemo(() => {
    let credentialsList: any[] = [];
    let selectedItem: ICardDetails = {};
    let tempMap = new Map();
    if (downloadActive) {
      selectedItem.contentStyle = {
        paddingBottom: { xs: '72px !important', sm: '24px !important' },
      };
    }

    switch (selectedId) {
      case userCredentials.OpenTasks:
        credentialsList = openRequirementItems;
        if (assignmentSort !== ALL_VALUE) {
          credentialsList = sortByAssignment(credentialsList, true);
        }

        if (sortValue === DUE_DATE_VALUE) {
          credentialsList = sortByDueDate(
            credentialsList,
            openRequirementsData,
          );
        } else if (sortValue === CATEGORIES_VALUE) {
          credentialsList = sortByAssignment(credentialsList, true);
        }

        selectedItem.verbiage = taskVerbiage;
        selectedItem.showSubtitle = false;
        selectedItem.isAccordion = sortValue === DUE_DATE_VALUE ? false : true;
        selectedItem.headerCategoryIcon = (
          <OpenRequirementsIcon htmlColor={theme.palette.system.midnightBlue} />
        );
        selectedItem.title = credentialsList.length > 0 ? 'Open Tasks' : '';
        selectedItem.numberOfItems = filteredOpenCredentialsOpenItems;
        selectedItem.noDataScreen =
          credentialsList.length > 0 ? undefined : <OpenTasksSplash />;
        selectedItem.filter1 = new IDropdown({
          label: 'Sort: ',
          menuItems: [
            { key: CATEGORIES_VALUE, value: 'Categories' },
            { key: DUE_DATE_VALUE, value: 'Due Date' },
          ],
          selectedValue: sortValue,
          disabled: false,
          handleOnChange: val => {
            setSortValue(val);
            // AppInsights Tracking: Open Tasks Sort Clicked
            trackEvent(`OpenTasks_Sort_Clicked`, {
              sortValue: val,
            });
          },
        });
        selectedItem.filter2 = new IDropdown({
          label: 'Assignment: ',
          menuItems: formatAssignmentList(credentials?.openRequirements),
          disabled:
            formatAssignmentList(credentials?.openRequirements).length === 0,
          selectedValue: assignmentSort,
          handleOnChange: val => {
            setAssignmentSort(val);
            // AppInsights Tracking: Open Tasks Filter Clicked
            trackEvent(`OpenTasks_Filter_Clicked`);
          },
        });
        break;
      case userCredentials.LICENSES:
        credentialsList = filterCredentials(
          userCredentials.LICENSES,
          credentials?.credentials,
        );
        selectedItem.showSubtitle = false;
        selectedItem.title = 'Licenses';
        selectedItem.headerLink1 = (
          <AssignmentLink
            id="nurse-licesure-guidance-link"
            underline="hover"
            justifyContent="end"
            onClick={() => {
              openInNewTab(NURSE_LICENSURE_GUIDE_URL);
            }}
          >
            Nurse Licensure Guidance
          </AssignmentLink>
        );
        selectedItem.noDataScreen =
          credentialsList.length > 0
            ? undefined
            : createNoDataScreen(
                <LicensesIcon
                  sx={{
                    color: theme.palette.system.gray,
                    width: '34px',
                    height: '32px',
                  }}
                />,
                NO_DATA_MESSAGE.CredentialWallet,
              );
        selectedItem = addDownload(
          selectedItem,
          credentialsList.length > 0,
          toggleDownloadDocs,
          documentDocs,
          downloadActive,
          downloadList,
        );
        break;
      case userCredentials.CERTIFICATIONS:
        credentialsList = filterCredentials(
          userCredentials.CERTIFICATIONS,
          credentials?.credentials,
        );
        selectedItem.showSubtitle = false;
        selectedItem.title = 'Certifications';
        selectedItem.noDataScreen =
          credentialsList.length > 0
            ? undefined
            : createNoDataScreen(
                <CertificationsIcon
                  sx={{
                    color: theme.palette.system.gray,
                    width: '34px',
                    height: '32px',
                  }}
                />,
                NO_DATA_MESSAGE.CredentialWallet,
              );
        selectedItem = addDownload(
          selectedItem,
          credentialsList.length > 0,
          toggleDownloadDocs,
          documentDocs,
          downloadActive,
          downloadList,
        );
        break;
      case userCredentials.EducationOnlineExams:
        credentialsList = filterCredentials(
          userCredentials.EducationOnlineExams,
          credentials?.credentials,
        );
        selectedItem.showSubtitle = false;
        selectedItem.title = 'Education & Exams';
        selectedItem.headerLink1 = (
          <AssignmentLink
            id="healthstream-link"
            underline="hover"
            justifyContent="end"
            onClick={() => {
              openInNewTab(HEALTHSTREAM_URL);
            }}
          >
            Healthstream
          </AssignmentLink>
        );
        selectedItem.noDataScreen =
          credentialsList.length > 0
            ? undefined
            : createNoDataScreen(
                <EducationOnlineExamsIcon
                  sx={{
                    color: theme.palette.system.gray,
                    width: '34px',
                    height: '32px',
                  }}
                />,
                NO_DATA_MESSAGE.CredentialWallet,
              );
        selectedItem = addDownload(
          selectedItem,
          credentialsList.length > 0,
          toggleDownloadDocs,
          documentDocs,
          downloadActive,
          downloadList,
        );
        break;
      case userCredentials.HumanResources:
        credentialsList = filterCredentials(
          userCredentials.HumanResources,
          credentials?.credentials,
        );
        credentialsList = credentialsList.map(item => {
          if (item?.title === 'Arbitration Agreement') {
            return {
              ...item,
              title: `${item.title} - ${item?.entityName}`,
            };
          }
          return item;
        });
        selectedItem.showSubtitle = false;
        selectedItem.title = 'Human Resources';
        selectedItem.noDataScreen =
          credentialsList.length > 0
            ? undefined
            : createNoDataScreen(
                <HumanResourcesIcon
                  sx={{
                    color: theme.palette.system.gray,
                    width: '34px',
                    height: '32px',
                  }}
                />,
                NO_DATA_MESSAGE.CredentialWallet,
              );
        selectedItem = addDownload(
          selectedItem,
          credentialsList.length > 0,
          toggleDownloadDocs,
          documentDocs,
          downloadActive,
          downloadList,
        );
        selectedItem.secondaryLinks = isLeadUser
          ? []
          : [
              <TextButton
                id="add-credential-button"
                icon={AddIcon(theme.palette.secondary.main, '17.05', '17.05')}
                onClick={openAddCredentialDrawer}
              >
                Add Credential
              </TextButton>,
              <TextButton
                id="add-identification-button"
                icon={AddIcon(theme.palette.secondary.main, '17.05', '17.05')}
                onClick={openAddIdentificationDrawer}
              >
                Add Identification
              </TextButton>,
            ];
        break;
      case userCredentials.EmployeeHealth:
        credentialsList = filterCredentials(
          userCredentials.EmployeeHealth,
          credentials?.credentials,
        );
        selectedItem.showSubtitle = false;
        selectedItem.title = 'Employee Health';
        selectedItem.noDataScreen =
          credentialsList.length > 0
            ? undefined
            : createNoDataScreen(
                <EmployeeHealthIcon
                  sx={{
                    color: theme.palette.system.gray,
                    width: '34px',
                    height: '32px',
                  }}
                />,
                NO_DATA_MESSAGE.CredentialWallet,
              );
        selectedItem = addDownload(
          selectedItem,
          credentialsList.length > 0,
          toggleDownloadDocs,
          documentDocs,
          downloadActive,
          downloadList,
        );
        selectedItem.secondaryLinks = isLeadUser
          ? []
          : [
              <TextButton
                id="add-credential-button"
                icon={AddIcon(theme.palette.secondary.main, '17.05', '17.05')}
                onClick={openAddCredentialDrawer}
              >
                Add Credential
              </TextButton>,
              <TextButton
                id="covid-vaccination-button"
                icon={AddIcon(theme.palette.secondary.main, '17.05', '17.05')}
                onClick={openCOVID19Drawer}
                maxWidth="125px"
              >
                Add COVID-19 Vaccination Record
              </TextButton>,
            ];
        break;
      case userCredentials.ClinicalQualifications:
        credentialsList = filterCredentials(
          userCredentials.ClinicalQualifications,
          credentials?.credentials,
        );
        selectedItem.showSubtitle = false;
        selectedItem.title = 'Clinical Qualifications';
        selectedItem.noDataScreen =
          credentialsList.length > 0
            ? undefined
            : createNoDataScreen(
                <ClinicalQualificationsIcon
                  sx={{
                    color: theme.palette.system.gray,
                    width: '34px',
                    height: '32px',
                  }}
                />,
                NO_DATA_MESSAGE.CredentialWallet,
              );
        selectedItem = addDownload(
          selectedItem,
          credentialsList.length > 0,
          toggleDownloadDocs,
          documentDocs,
          downloadActive,
          downloadList,
        );
        break;
      case userCredentials.VaccinationRecord:
        credentialsList = filterCredentials(
          userCredentials.VaccinationRecord,
          credentials?.credentials,
        );
        selectedItem.showSubtitle = false;
        selectedItem.title = 'COVID-19 Vaccination Record';
        break;
      case userCredentials.Submissions:
        credentialsList = (walletSubmittedCredentials.items || []).map(
          (submission: Partial<ICredentialSubmission>) => {
            return {
              ...submission,
              categoryId: userCredentials.Submissions,
            };
          },
        );
        if (submittedAssignmentCredentials) {
          credentialsList = orderCredentialSubmissions(
            credentialsList.concat(
              formatAssignmentCredentialSubmissions(
                submittedAssignmentCredentials,
              ),
            ),
          );
        }
        selectedItem.showSubtitle = false;
        selectedItem.title = 'Submissions';
        selectedItem.noDataScreen =
          credentialsList.length > 0 || !!submittedAssignmentCredentials?.length
            ? undefined
            : createNoDataScreen(undefined, NO_DATA_MESSAGE.NoSubmisionsFound);
        if (
          credentials?.submissions.paging.limit <
            credentials?.submissions.paging.total &&
          parseInt(credentials?.submissions?.paging?.offset) === 0 &&
          parseInt(credentials?.submissions?.paging?.total) > 10
        )
          selectedItem.showMore = onShowMore;
        break;
      default:
        selectedItem.showSubtitle = false;
        break;
    }
    if (!isLeadUser) {
      if (selectedId === userCredentials.OpenTasks) {
        selectedItem.headerLink2 =
          selectedItem.isAccordion && credentialsList.length > 0 ? (
            <Button
              id="expand-accordion"
              variant="text"
              onClick={() =>
                setExpandAccordion(!expandAccordion ? 'ALL' : undefined)
              }
              sx={{
                fontSize: '14px',
                fontWeight: 'normal',
                textAlign: 'right',
                padding: 0,
                height: 'initial',
              }}
            >{`${!!expandAccordion ? 'collapse' : 'expand'} all`}</Button>
          ) : undefined;
      } else if (
        !(
          selectedId === userCredentials.EmployeeHealth ||
          selectedId === userCredentials.HumanResources
        )
      ) {
        selectedItem.headerLink2 = (
          <AssignmentLink
            id="add-credential-or-verify-license-link"
            underline="hover"
            leftIcon={AddIcon(theme.palette.secondary.main, '17.05', '17.05')}
            justifyContent="start"
            onClick={openAddCredentialDrawer}
          >
            {selectedId !== userCredentials.LICENSES
              ? 'Add Credential'
              : 'Verify License'}
          </AssignmentLink>
        );
      }
    }

    selectedItem.headerLink2Click = () => {};
    if (selectedId) {
      if (
        !selectedItem.isAccordion &&
        selectedId === userCredentials.OpenTasks
      ) {
        tempMap.set(selectedId, { items: credentialsList });
        selectedItem.items = tempMap;
      } else if (!selectedItem.isAccordion) {
        tempMap.set(selectedId, {
          items: credentialsList.map(item => {
            if (item?.categoryId === userCredentials.Submissions) {
              return {
                id: item.id,
                ...itemsOtherPropsSubmission(
                  item,
                  setSelectedSubmission,
                  setShowDetailsDrawer,
                ),
                sx: {
                  color: 'white',
                },
              } as ICard;
            }

            return {
              id: item.id,
              title: item.title,
              visualIndicator: {
                content: returnBadgeStatus(
                  item.statusId,
                  !!item.assignmentCredential,
                  true,
                  item.expirationDate,
                ),
                sx: {
                  '& .MuiBadge-badge': {
                    fontWeight: 700,
                  },
                },
                color: visualIndicatorColor(
                  returnBadgeStatus(
                    item.statusId,
                    !!item.assignmentCredential,
                    true,
                    item.expirationDate,
                  ),
                ),
              },
              ...itemsOtherProps(
                item,
                setSelectedDetailsItem,
                setShowDetailsDrawer,
                dispatch,
                downloadActive,
                toggleDownloads,
                downloadList,
              ),
            } as ICard;
          }),
        });
        selectedItem.items = tempMap;
      } else if (
        selectedItem.isAccordion &&
        selectedId === userCredentials.OpenTasks
      ) {
        selectedItem.items = tempMap.set(userCredentials.OpenTasks, {
          items: sortOpenRequirements(
            credentialsList,
            setSelectedOpenTask,
            expandAccordion?.toString(),
            setExpandAccordion,
            dispatch,
            isSafariOrFirefox ? skillListUrl : undefined,
            history,
          ),
        });
        setDefaultExpandedAccordion(
          Object.keys(
            selectedItem.items.get(userCredentials.OpenTasks).items,
          )[0],
        );
      }
    }
    selectedItem.leftBackHeaderClick = () => {
      isMobileDevice && setSelectedId(userCredentials.OpenTasks);
    };
    return selectedItem;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedId,
    credentials,
    downloadActive,
    sortValue,
    assignmentSort,
    toggleDownloadDocs,
    downloadList,
    matchesMinWidth1024,
    expandAccordion,
    setExpandAccordion,
    isSafariOrFirefox,
    isMobileDevice,
    showAllAssignments,
  ]);

  const cardListData = useMemo(
    () =>
      ({
        showSearch: true,
        showTitle: true,
        cardList: {
          [userCredentials.OpenTasks]: useOpenRequirementsFlag
            ? {
                order: 1,
                showComponent: isMobileDevice,
                setComponentData:
                  isMobileDevice &&
                  selectedId === undefined &&
                  setSelectedId(userCredentials.OpenTasks),
                items: [
                  {
                    id: userCredentials.OpenTasks,
                    title: 'Open Tasks',
                    icon: <TaskIcon />,
                    onClick: () => {
                      setSelectedId(userCredentials.OpenTasks);
                      // AppInsights Tracking: Navigating to Open Tasks card
                      trackEvent('OpenTasks_Viewed');
                    },
                    visualIndicator: {
                      content: filteredOpenCredentialsOpenItems,
                      sx: {
                        '& .MuiBadge-badge': {
                          fontWeight: 700,
                        },
                      },
                    },
                  },
                ],
              }
            : undefined,
          [userCredentials.MyDocuments]: {
            order: 3,
            title: (
              <Typography variant="subtitle1" py={2}>
                My Documents
              </Typography>
            ),
            items: [
              {
                id: userCredentials.LICENSES,
                title: 'Licenses',
                icon: (
                  <LicensesIcon htmlColor={theme.palette.system.midnightBlue} />
                ),
                numberOfItems: filterCredentials(
                  userCredentials.LICENSES,
                  credentials?.credentials,
                ).length,
                onClick: () => {
                  setShowDrawer(true);
                  setSelectedId(userCredentials.LICENSES);
                  // AppInsights Tracking: Credential Category Page Viewed
                  trackEvent(
                    `TaskCenterPage_Category_Viewed_${userCredentials.LICENSES}`,
                    {
                      categoryId: userCredentials.LICENSES,
                      category: 'Licenses',
                    },
                  );
                },
              },
              {
                id: userCredentials.CERTIFICATIONS,
                title: 'Certifications',
                icon: (
                  <CertificationsIcon
                    htmlColor={theme.palette.system.midnightBlue}
                  />
                ),
                numberOfItems: filterCredentials(
                  userCredentials.CERTIFICATIONS,
                  credentials?.credentials,
                ).length,
                onClick: () => {
                  setShowDrawer(true);
                  setSelectedId(userCredentials.CERTIFICATIONS);
                  // AppInsights Tracking: Credential Category Page Viewed
                  trackEvent(
                    `TaskCenterPage_Category_Viewed_${userCredentials.CERTIFICATIONS}`,
                    {
                      categoryId: userCredentials.CERTIFICATIONS,
                      category: 'Certifications',
                    },
                  );
                },
              },
              {
                id: userCredentials.EducationOnlineExams,
                title: 'Education & Exams',
                icon: (
                  <EducationOnlineExamsIcon
                    htmlColor={theme.palette.system.midnightBlue}
                  />
                ),
                numberOfItems: filterCredentials(
                  userCredentials.EducationOnlineExams,
                  credentials?.credentials,
                ).length,
                onClick: () => {
                  setShowDrawer(true);
                  setSelectedId(userCredentials.EducationOnlineExams);
                  // AppInsights Tracking: Credential Category Page Viewed
                  trackEvent(
                    `TaskCenterPage_Category_Viewed_${userCredentials.EducationOnlineExams}`,
                    {
                      categoryId: userCredentials.EducationOnlineExams,
                      category: 'Education & Exams',
                    },
                  );
                },
              },
              {
                id: userCredentials.HumanResources,
                title: 'Human Resources',
                icon: (
                  <HumanResourcesIcon
                    htmlColor={theme.palette.system.midnightBlue}
                  />
                ),
                numberOfItems: filterCredentials(
                  userCredentials.HumanResources,
                  credentials?.credentials,
                ).length,
                onClick: () => {
                  setShowDrawer(true);
                  setSelectedId(userCredentials.HumanResources);
                  // AppInsights Tracking: Credential Category Page Viewed
                  trackEvent(
                    `TaskCenterPage_Category_Viewed_${userCredentials.HumanResources}`,
                    {
                      categoryId: userCredentials.HumanResources,
                      category: 'Human Resources',
                    },
                  );
                },
              },
              {
                id: userCredentials.EmployeeHealth,
                title: 'Employee Health',
                icon: (
                  <EmployeeHealthIcon
                    htmlColor={theme.palette.system.midnightBlue}
                  />
                ),
                numberOfItems: filterCredentials(
                  userCredentials.EmployeeHealth,
                  credentials?.credentials,
                ).length,
                onClick: () => {
                  setShowDrawer(true);
                  setSelectedId(userCredentials.EmployeeHealth);
                  // AppInsights Tracking: Credential Category Page Viewed
                  trackEvent(
                    `TaskCenterPage_Category_Viewed_${userCredentials.EmployeeHealth}`,
                    {
                      categoryId: userCredentials.EmployeeHealth,
                      category: 'Employee Health',
                    },
                  );
                },
              },
              {
                id: userCredentials.ClinicalQualifications,
                title: 'Clinical Qualifications',
                icon: (
                  <ClinicalQualificationsIcon
                    htmlColor={theme.palette.system.midnightBlue}
                  />
                ),
                numberOfItems: filterCredentials(
                  userCredentials.ClinicalQualifications,
                  credentials?.credentials,
                ).length,
                onClick: () => {
                  setShowDrawer(true);
                  setSelectedId(userCredentials.ClinicalQualifications);
                  // AppInsights Tracking: Credential Category Page Viewed
                  trackEvent(
                    `TaskCenterPage_Category_Viewed_${userCredentials.ClinicalQualifications}`,
                    {
                      categoryId: userCredentials.ClinicalQualifications,
                      category: 'Clinical Qualifications',
                    },
                  );
                },
              },
              {
                id: userCredentials.Submissions,
                title: 'Submitted Credentials',
                icon: (
                  <SubmissionsIcon
                    htmlColor={theme.palette.system.midnightBlue}
                  />
                ),
                onClick: () => {
                  setShowDrawer(true);
                  setSelectedId(userCredentials.Submissions);
                  // AppInsights Tracking: Credential Submissions Page Viewed
                  trackEvent('TaskCenterPage_Submissions_Viewed');
                },
              },
            ],
          },
        } as ICard,
      } as ICardList),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      credentials?.credentials,
      credentials.openRequirements,
      useOpenRequirementsFlag,
      useDocusignFlag,
      isMobileDevice,
      cardDetailsData?.leftBackHeaderClick,
    ],
  );

  const handleHelpContact = useCallback(
    () =>
      assignmentSort !== ALL_VALUE && selectedId === userCredentials.OpenTasks
        ? credentialChecklistData?.helpContact
        : undefined,
    [assignmentSort, credentialChecklistData?.helpContact, selectedId],
  );

  const cardDrawerContent = useMemo(
    () => (
      <DrawerPanel
        alertId={AlertBarType.CREDENTIAL_CENTER}
        showFooter={false}
        open={showDrawer}
        toggleDrawer={(e, open) => setShowDrawer(open)}
        contentName={cardDetailsData?.title || 'Credentials'}
        headerType={DrawerHeaderType.Back}
        drawerContainerStyles={{ display: { xs: 'block', sm: 'none' } }}
        backRightAdornment={cardDetailsData?.headerIcon}
        backRightAdornmentClick={cardDetailsData?.headerIconClick}
        leftBackHeaderClick={cardDetailsData?.leftBackHeaderClick}
        centerTitle={!matchesMinWidth1024}
      >
        <CardDetails
          cardDetails={cardDetailsData}
          expandAccordion={expandAccordion}
          helpContact={handleHelpContact()}
        />
      </DrawerPanel>
    ),
    [
      showDrawer,
      cardDetailsData,
      matchesMinWidth1024,
      expandAccordion,
      handleHelpContact,
    ],
  );

  const documentContent = useMemo(() => {
    let url = credentials.docData?.data
      ? convertBase64ToUrl(
          credentials.docData?.fileType ?? '',
          credentials.docData?.data ?? '',
        )
      : '';

    return (
      <DrawerPanel
        open={showDetailsDrawer}
        showFooter={false}
        toggleDrawer={(e, open) => {
          if (!open) {
            dispatch(credentialActions.resetCredentialDetailDoc());
            dispatch(credentialActions.setArbitrationAgreement(undefined));
            setSelectedDetailsItem(undefined);
            setSelectedSubmission(undefined);
          }
          setShowDetailsDrawer(open);
        }}
        contentName={
          (selectedSubmission?.licenseVerificationDetails
            ? getRnOrLpnContentName(
                selectedSubmission.licenseVerificationDetails.disciplineId,
              )
            : selectedId === userCredentials.Submissions
            ? selectedSubmission?.title &&
              selectedSubmission?.title.length > 27 &&
              selectedSubmission.type === SubmissionType.Wallet
              ? selectedSubmission?.title.substring(0, 27) + '...'
              : selectedSubmission?.title
            : selectedDetailsItem?.title) || 'Form'
        }
        contentStyles={{
          height:
            selectedId === userCredentials.Submissions &&
            (selectedSubmission?.fileUrl || '')?.endsWith(
              'jpg' || 'jpeg' || 'png',
            )
              ? 'auto'
              : '100%',
        }}
        innerContainerStyles={{
          width: {
            xs: '100%',
            sm: selectedDetailsItem?.arbitrationFileUrl
              ? '800px !important'
              : 'auto',
          },
        }}
        // uncomment for download feature
        // backRightAdornment={
        //   selectedDetailsItem?.arbitrationFileUrl && (
        //     <DownloadIcon
        //       sx={{
        //         cursor: 'pointer',
        //         marginTop: '5px',
        //       }}
        //       htmlColor={theme.palette.system.skyBlue}
        //       width="18px"
        //       height="18px"
        //     />
        //   )
        // }
      >
        <>
          {selectedSubmission &&
          selectedSubmission.type === SubmissionType.Checklist &&
          selectedId === userCredentials.Submissions ? (
            <UploadedCredentialDocuments
              selectedOpenRequirementsItem={selectedSubmission}
            />
          ) : selectedId === userCredentials.Submissions ? (
            <>{getDrawerChildrenSubmission(selectedSubmission, stateList)}</>
          ) : (
            <>
              {getDrawerChildren(
                selectedDetailsItem,
                selectedId,
                signedArbitrationAgreement,
              )}
              {selectedDetailsItem?.isSecureDocument ? (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  pt="55%"
                >
                  <Typography variant="subtitle1" color="system.coolGray">
                    <i>{NO_DATA_MESSAGE.DisabledForSecurity}</i>
                  </Typography>
                </Grid>
              ) : credentials.isDocDataLoading ? (
                <AppCircularProgress />
              ) : credentials.docData?.data ? (
                <Box
                  sx={{
                    paddingTop: '24px',
                    height: selectedSubmission?.fileUrl?.endsWith(
                      'pdf' || 'doc' || 'docx',
                    )
                      ? '80%'
                      : 'auto',
                  }}
                >
                  <IFrameDrawer
                    url={url}
                    fileType={credentials.docData?.fileType}
                    source={MenuIcons.TaskCenter}
                    iframeHeight={'65vh'}
                  />
                </Box>
              ) : (
                !selectedDetailsItem?.arbitrationFileUrl && (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    pt="55%"
                  >
                    <Typography variant="subtitle1" color="system.coolGray">
                      <i>No documents available.</i>
                    </Typography>
                  </Grid>
                )
              )}
            </>
          )}
          {selectedSubmission?.licenseVerificationDetails &&
            selectedId === userCredentials.Submissions && (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                pt="55%"
              >
                <Typography variant="subtitle1" color="system.coolGray">
                  <i>No documents available.</i>
                </Typography>
              </Grid>
            )}
        </>
      </DrawerPanel>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    credentials?.docData,
    showDetailsDrawer,
    dispatch,
    credentials.isDocDataLoading,
    signedArbitrationAgreement,
    selectedDetailsItem,
  ]);

  useEffect(() => {
    if (assignmentSort && assignmentSort !== ALL_VALUE) {
      dispatch(credentialActions.getCredentialChecklist(+assignmentSort));
    }
  }, [assignmentSort, drawerOpen]);

  useEffect(() => {
    if (assignmentSort && isAssignmentProfileTasksEnabled) {
      const hasNoOpenTasks =
        sortByAssignment(openRequirementItems, false).length === 0;
      if (hasNoOpenTasks) {
        setAssignmentSort(ALL_VALUE);
      }
    }
  }, [assignmentSort, openRequirementItems, isAssignmentProfileTasksEnabled]);

  // Sets main left side nav category ID item
  useEffect(() => {
    if (categoryId) {
      setSelectedId(Number(categoryId));
    }
    if (placementId) setAssignmentSort(+placementId);
    if (openTasks) {
      setSelectedId(userCredentials.OpenTasks);
    }
    if (credentialTypeId) {
      setExpandAccordion(credentialTypeId);
    }
    if (credentialId) {
      filterRedirectedOpenRequirement(
        openRequirementItems,
        credentialId,
        setSelectedOpenTask,
      );
    }

    // For deeplink My Document credential redirect item
    if (documentItemId) {
      const credentialWalletItem = credentials.credentials.find(
        item => item.id === Number(documentItemId),
      );
      if (credentialWalletItem) {
        if (!credentialWalletItem.isSecureDocument)
          dispatch(
            credentialActions.getCredentialDetailDoc(credentialWalletItem?.id),
          );
        setSelectedDetailsItem(credentialWalletItem);
        setSelectedId(credentialWalletItem.categoryId);
        setShowDetailsDrawer(true);
      }
    }

    if (documentCategory) {
      setSelectedId(Number(documentCategory));
      setShowDrawer(true);
    }

    history.replace(`/${AppRouteNames.TASK_CENTER}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    categoryId,
    credentialTypeId,
    credentials.credentials,
    documentCategory,
    documentItemId,
    openTasks,
    placementId,
  ]);

  // Docusign Event listener
  useEffect(() => {
    if (
      history.location &&
      (history.location.search.includes('signing_complete') ||
        history.location.search.includes('viewing_complete'))
    ) {
      dispatch(credentialActions.getCredentialDetailAction(true));
      history.replace(`/${AppRouteNames.TASK_CENTER}`);
    } else if (
      history.location &&
      history.location.search.includes('cancel') &&
      !credentialTypeId
    ) {
      setSelectedId(userCredentials.OpenTasks);
      history.replace(`/${AppRouteNames.TASK_CENTER}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  // For Safari/Firefox browsers - Skills Checklist messaging/event listeners
  useEffect(() => {
    // due to the action being attached incorrectly after all the params,
    // we need to parse the url to grab the action
    const action = history.location.search.slice(
      history.location.search.indexOf('action') + 7,
    );
    // due to the action being attached incorrectly after all the params,
    // we need to parse credentialType and remove extra query param
    const credentialTypeId = (query.get('credentialType') || '')[0];
    if (
      isSafariOrFirefox &&
      !!action &&
      !!credentialTypeId &&
      +credentialTypeId === OpenRequirementCredentialType.Skills_Checklists
    ) {
      setSelectedId(userCredentials.OpenTasks);
      setExpandAccordion(credentialTypeId);
      setTimeout(() => {
        // Delete from query params since they are no longer needed
        history.replace(`/${AppRouteNames.TASK_CENTER}`);
      }, 1);
    }
    return () => {
      dispatch(resetSkillsList());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSafariOrFirefox]);

  /* For Safari/Firefox browsers - Formstack messaging/event listeners
  Also handling deeplinks for credential center
  */
  useEffect(() => {
    const credentialTypeId = (query.get('credentialType') || '')[0];
    const isSubmitted = query.get('formstackSubmitted');
    if (
      isSafariOrFirefox &&
      !!credentialTypeId &&
      +credentialTypeId === OpenRequirementCredentialType.Forms &&
      isSubmitted
    ) {
      setTimeout(() => {
        // Delete from query params since they are no longer needed
        history.replace(`/${AppRouteNames.TASK_CENTER}`);
      }, 1);
    }

    // For deeplink credential center redirect item
    const envelopeId = query.get('envelopeId');
    const isDocuSignItem = query.get('docusign');
    if (!isSubmitted) {
      if (envelopeId) {
        setSelectedId(userCredentials.OpenTasks);
        setExpandAccordion(credentialTypeId);
      }
      setTimeout(() => {
        history.replace(`/${AppRouteNames.TASK_CENTER}`);
      }, 1);
    }
    if (credentialTypeId) {
      setExpandAccordion(credentialTypeId);
      setSelectedId(userCredentials.OpenTasks);
    }
    if (isDocuSignItem) {
      setSelectedId(userCredentials.OpenTasks);
      setExpandAccordion(credentialTypeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSafariOrFirefox]);

  useEffect(() => {
    dispatch(setActivePage(MenuIcons.TaskCenter));
    dispatch(credentialActions.getSubmissions());
    dispatch(credentialActions.getAssignmentCredentialSubmission());
    dispatch(credentialActions.getCredentialDetailAction(false));
    if (!isDisciplineLoading) {
      dispatch(
        fetchDiscipline({
          offset: '0',
          limit: '60',
          disciplineId: userDisciplineId
            ? userDisciplineId.toString()
            : `${UserDisciplineTypeId.NURSE},${UserDisciplineTypeId.ALLIED}`,
        }),
      );
    }
    return () => {
      dispatch(fileUploadsActions.reset());
      dispatch(credentialActions.setFormStackData({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Expand accordion for Open Tasks is set to 1st item when selected
  useEffect(() => {
    if (selectedId === userCredentials.OpenTasks && !expandAccordion) {
      setExpandAccordion(defaultExpandedAccordion);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  // AppInsights Tracking: Navigating to Credentialing Center page
  useEffect(() => {
    trackEvent('TaskCenterPage_Viewed');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      if (loadedForTagManager) {
        dispatch(
          dataLayerActions.dataLayerReadyAction(DataLayerPageName.CREDENTIALS),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadedForTagManager],
  );

  const handleSearchResultClick = () => {
    trackEvent('TaskCenterPage_Search_Viewed');
  };

  return cardListData && cardDetailsData ? (
    <>
      <CardListPage
        id="credentials-page"
        selectedId={selectedId}
        cardListData={cardListData}
        cardDetails={cardDetailsData}
        splashScreen={
          selectedId !== userCredentials.OpenTasks ? (
            <TaskCenterSplash />
          ) : (
            <Card
              sx={{
                display: 'flex',
                width: '555px',
                borderRadius: '10px',
                boxShadow: {
                  sm: `0px 1px 15px ${theme.palette.system.boxShadowGray}`,
                },
              }}
            >
              <OpenTasksSplash />
            </Card>
          )
        }
        showSplashScreen={
          credentials.openRequirements.length === 0 &&
          selectedId === userCredentials.OpenTasks
        }
        searchItems={allOpenRequirements}
        searchKey="title"
        onSearchBarFocus={onSearchBarFocus}
        onSearchDownloadIconClick={toggleDownloadDocs}
        onSearchDownloadClick={documentDocs}
        leftBackHeaderClick={cardDetailsData?.leftBackHeaderClick}
        enableSearchBar
        expandAccordion={
          cardDetailsData?.isAccordion ? expandAccordion : undefined
        }
        helpContact={handleHelpContact()}
        searchResultOnClick={handleSearchResultClick}
      />
      {cardDrawerContent}
      {documentContent}
      <TaskActionsManager
        selectedPlacementId={selectedPlacementId}
        setSelectedPlacementId={setSelectedPlacementId}
        selectedOpenTask={selectedOpenTask}
        setSelectedOpenTask={setSelectedOpenTask}
      />
      {!isLeadUser && (
        <Button
          onClick={openAddCredentialDrawer}
          variant="round"
          sx={{
            position: 'fixed',
            bottom: '106px',
            right: '36px',
            zIndex: 1000,
            display: { sm: 'none' },
          }}
        >
          {AddIcon(theme.palette.system.white, '23px', '23px')}
        </Button>
      )}
      {credentialSubmissionConfirmationPopup(
        dispatch,
        openAddCredentialDrawer,
        isOpen,
      )}
    </>
  ) : null;
};
