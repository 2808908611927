import { IAssignmentSummary } from 'interfaces/Assignment/AssignmentSummary';
import { IReimbursementRequest } from 'interfaces/Reimbursement/IReimbursementForm';
import { ICalculateReimbursement } from 'interfaces/Reimbursement/ICalculateReimbursement';
import { http } from 'services/BaseApi';

export const getReimbursementService = async (
  userId: string,
  offset: number,
  limit: number,
  isLocums: boolean,
): Promise<IAssignmentSummary> => {
  return isLocums
    ? await http.get<string>(
        `/reimbursement/${userId}?offset=${offset}&limit=${limit}`,
        {
          headers: { 'x-userType': 'locums' },
        },
      )
    : await http.get<string>(
        `/reimbursement/${userId}?offset=${offset}&limit=${limit}`,
      );
};

export const postReimbursementRequest = async (
  userId: string,
  body: IReimbursementRequest,
): Promise<any> => {
  return await http.post<IReimbursementRequest>(
    `/v3/reimbursement/${userId}`,
    body,
  );
};

export const postReimbursementDocumentRequest = async (
  userId: string,
  body: any,
): Promise<any> => {
  return await http.postUpload<any>(`/reimbursement/${userId}/document`, body);
};

export const fetchMileageReimbursement = async (
  userId: string,
  requestBody: ICalculateReimbursement,
  isLocums: boolean,
): Promise<{ mileage: number }> => {
  return isLocums
    ? await http.post<any>(`reimbursement/${userId}/distance`, requestBody, {
        headers: { 'x-userType': 'locums' },
      })
    : await http.post<any>(`reimbursement/${userId}/distance`, requestBody);
};
