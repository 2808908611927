import moment from 'moment';

/**
 * Method to get only date from date time
 */
const getFormattedDateOnly = (dateTime: string | Date, format?: string) => {
  const formatString = format ? format : 'MM/DD';

  if (dateTime instanceof Date) {
    return moment(dateTime).format(formatString);
  }

  const date = dateTime?.split('T')[0];
  return moment(date).format(formatString);
};

/**
 * Method to get month
 */
const getMonth = (monthId: number) => moment(monthId, 'MM').format('MMMM');

/**
 * Method to get time with (A|P)M
 */
const getTime = (time: string) =>
  moment.utc(moment.duration(time).asMilliseconds()).format('h:mm A');

/**
 * Method to sort date by descending
 */
const sortByDate = (arr: any[], fieldName: string) => {
  const sorter = (a, b) => {
    return new Date(a[fieldName]).getTime() - new Date(b[fieldName]).getTime();
  };
  return arr.sort(sorter);
};

const sortByDateDescending = (arr: any[], fieldName: string) => {
  const sorter = (a, b) => {
    return new Date(b[fieldName]).getTime() - new Date(a[fieldName]).getTime();
  };
  return arr.sort(sorter);
};

const getMMddyyyy = (date: string) => getFormattedDateOnly(date, 'MM/DD/yyyy');

const getMMMddyyyy = (date: string) =>
  getFormattedDateOnly(date, 'MMM DD, yyyy');

const getMMMdd = (date: string) => getFormattedDateOnly(date, 'MMM DD');

const getMMMddyyyyhmmA = (date: string) =>
  moment(date).format('MMM DD, yyyy h:mm A');

const getMMMddyyyyhmmAz = (date: string) => {
  const day = moment(date).format('MMM DD, yyyy');
  const time = moment(date).format('h:mm A z');
  return `${day} at ${time}`;
};

/**
 * Method to get month, year and time from a date
 */
const getMMMddyyyyhhmmampm = (date: string) =>
  moment(date).format('MMM DD, yyyy hh:mma');

const getTimestampFormat = (date: string | moment.Moment) =>
  `${moment(date).format('YYYY-MM-DDTHH:mm:ss')}Z`;

export {
  sortByDate,
  sortByDateDescending,
  getMonth,
  getTime,
  getMMddyyyy,
  getMMMddyyyy,
  getMMMdd,
  getMMMddyyyyhmmA,
  getMMMddyyyyhmmAz,
  getMMMddyyyyhhmmampm,
  getTimestampFormat,
  getFormattedDateOnly,
};
